import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import currencies from "../../../assets/files/currencies.json";

import Card from "../../../common/Card/Card";
import { configs } from "../../../constants";
import { InvoiceTypes } from "../../../constants/globalConstants";
import { SelectStyles } from "../../../constants/styledComponents";
import { COLORS } from "../../../constants/theme";
import { InvoiceStep } from "../../../constants/types";
import { useAuth } from "../../../contexts";
import { getUserLS } from "../../../modules/localStorage";
import { filterContacts } from "../../../repositories/contactRepository";
import {
  saveInvoice,
  updateInvoice,
} from "../../../repositories/invoiceRepository";
import { formatCashflowMapping } from "../../../modules/formatCashflowMapping";
import { MapCategory } from "../../Contacts/components/MapCategory";
import { If } from "../../../common/Utils/If";

const INITIAL_STATE = {
  name: "",
  amount: "",
  dueDate: moment().format("YYYY-MM-DD"),
  issueDate: moment().format("YYYY-MM-DD"),
  description: "",
  referenceNumber: "",
  currency: ""
};

interface AddRecordType {
  containerStyles?: any;
  cardStyles?: any;
  files?: any;
  onSubmit?: any;
  recordType?: string;
  selectedBill?: Record<string, any>;
  handleUpdateStep: Function;
  record?: Record<string, any>;
}

function AddRecord({
  containerStyles = {},
  cardStyles = {},
  files,
  onSubmit,
  recordType,
  selectedBill,
  handleUpdateStep,
  record: _record = {},
}: any) {
  const user = getUserLS();
  const {
    getRecords,
    externalAccounts: _externalAccounts,
    fetchExternalAccounts,
    selectedScenario,
  } = useAuth();

  const [record, setRecord] = useState<any>({ ...INITIAL_STATE, ..._record });
  let contactType = recordType === InvoiceTypes.Payable ? "Vendor" : "Customer";
  let recordTypeName = recordType === InvoiceTypes.Payable ? "Bill" : "Invoice";

  const [selectedCashflowBucket, setSelectedCashflowBucket] = useState<any>(
    recordType || ""
  );
  const [externalAccounts, setExternalAccounts] =
    useState<any[]>(_externalAccounts);
  const [selectedName, setSelectedName] = useState(record?.name || "");

  const [contact, setContact] = useState<any>();

  useEffect(() => {
    console.log("###INSIDE AddRecord Component###");
    if (!selectedName || selectedName === "") {
      setContact(undefined);
      return;
    }

    const contact = _externalAccounts.find(
      (account: any) => account.displayName === selectedName
    );
    if (contact) {
      setContact(contact);
    }
  }, [selectedName, _externalAccounts]);

  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    if (!selectedBill) {
      return;
    }


    setRecord({
      name: selectedBill.name,
      referenceNumber: selectedBill.invoiceNumber,
      description: selectedBill.description,
      dueDate: selectedBill.dueDate,
      amount: selectedBill.amountTotal,
      issueDate: selectedBill.issueDate,
      currency: selectedBill.currency
    });
    setSelectedCashflowBucket(selectedBill.cashflowBucket);
    setSelectedName(selectedBill.name);
  }, [selectedBill]);

  useEffect(() => {
    setOptions(
      _externalAccounts.map((account: any) => ({
        value: account.displayName,
        label: account.displayName,
        disabled:
          !account.types.includes(contactType) &&
          !account.types.includes("Employee"),
      }))
    );
  }, [_externalAccounts]);

  useEffect(() => {
    if (!selectedName) {
      setExternalAccounts(_externalAccounts);
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      const filteredContacts = await filterContacts(selectedName);

      setOptions(
        _.uniqBy(filteredContacts, "id").map((account: any) => ({
          value: account.displayName,
          label: account.displayName,
          disabled:
            !account.types.includes(contactType) &&
            !account.types.includes("Employee"),
        }))
      );
      setExternalAccounts((prev) => _.uniqBy(filteredContacts, "id"));
    }, 0);

    return () => clearTimeout(delayDebounceFn);
  }, [selectedName]);

  const handleBillChange = (e: any) => {
    const { name, value } = e.target;
    setRecord({ ...record, [name]: value });
  };

  const createBill = async (type: string) => {
    if (selectedName === "") {
      return showNotification({
        color: "red",
        message: `Please enter ${contactType} name!`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }

    if (!record.amount) {
      return showNotification({
        color: "red",
        message: `Amount Required!`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    } else if (
      parseFloat(record.amount) <= 0 ||
      _.isNaN(parseFloat(record.amount))
    ) {
      return showNotification({
        color: "red",
        title: "Invalid Amount",
        message: "Amount should be greater than 0",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }

    let data = new FormData();
    if (files && files.length > 0) {
      var imagedata = files[0];
      data.append("image", imagedata);
    }
    if (selectedBill && selectedBill.id) {
      data.append(
        "inv",
        JSON.stringify({
          ...record,
          name: selectedName,
          amountPaid: "0",
          amountTotal: record.amount.toString(),
          dueDate: moment(record.dueDate).format("YYYY-MM-DD"),
          issueDate: moment(record.issueDate).format("YYYY-MM-DD"),
          type: recordType,
          tags: "",
          expectedDueDate: moment(record.dueDate).format("YYYY-MM-DD"),
          invoiceNumber: record.referenceNumber,
          cashflowCategory: selectedCashflowBucket,
          cashflowBucket: selectedCashflowBucket,
        })
      );
    } else {
      data.append(
        "inv",
        JSON.stringify({
          ...record,
          name: selectedName,
          amountPaid: "0",
          amountDue: record.amount.toString(),
          dueDate: moment(record.dueDate).format("YYYY-MM-DD"),
          issueDate: moment(new Date()).format("YYYY-MM-DD"),
          type: recordType,
          tags: "",
          expectedDueDate: moment(record.dueDate).format("YYYY-MM-DD"),
          invoiceNumber: record.referenceNumber,
          cashflowCategory: selectedCashflowBucket,
        })
      );
    }

    axios.defaults.withCredentials = true;
    try {
      let res: Record<string, any> = {};
      if (selectedBill && selectedBill.id) {
        res = await updateInvoice(data, selectedBill.id);
      } else {
        res = await saveInvoice(data);
      }

      if (res.data.success) {
        getRecords(undefined, 0, recordType, "dueDate", "DESC", {});
        // fetchCashflowReport();
        showNotification({
          message:
            recordTypeName +
            `${selectedBill && selectedBill.id ? " Updated!" : " Created!"} `,
          color: "teal",
          icon: <FontAwesomeIcon icon={faCheck} />,
        });
        if (type === "create_and_another") {
          setSelectedName("");
          setRecord(INITIAL_STATE);
        } else return onSubmit({ ...record, selectedCashflowBucket });
      } else {
        showNotification({
          color: "red",
          message: `Cannot save invoice!`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      }
    } catch (err) {
      showNotification({
        color: "red",
        message: `${err}`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
  };

  const saveContact = (name: string) => {
    setSelectedName("");
    axios
      .post(
        configs.urls.BASE_URL + "/contact/create",
        {
          name: name,
          displayName: name,
          type: contactType,
          createDestinationWithLean: false,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          showNotification({
            color: "teal",
            message: "Contact was saved!",
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
          fetchExternalAccounts();
          setSelectedName(name);
        } else {
          showNotification({
            color: "red",
            title: "Error!",
            message: res.data.errors,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `${err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      });
  };

  return (
    <Container style={{ ...containerStyles }}>
      <Card
        className="add-record-card-container"
        style={{
          ...cardStyles,
        }}
        isNormal
        noShadow
      >
        <CardTitle>{recordTypeName} Details</CardTitle>

        <InputContainer>
          <InputLabel>
            {contactType} {recordTypeName === "Bill" ? "or Employee" : ""} name
          </InputLabel>
          <Select
            name="selectedName"
            value={selectedName}
            data={options}
            nothingFound={
              selectedName === "" ? "Type to search contacts" : "Nothing found"
            }
            searchable
            creatable
            clearable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              const item = { value: query, label: query };
              setSelectedName(query);
              setOptions([...options, { value: query, label: query }]);
              setRecord({ ...record, name: query });
              saveContact(query);
              return item;
            }}
            onChange={(value: string) => {
              setSelectedName(value);
              setRecord({ ...record, name: value });
            }}
            onSearchChange={(query: string) => {
              setSelectedName(query);
              setRecord({ ...record, name: query });
            }}
            filter={(value, item) =>
              item.value.toLowerCase().includes(value.toLowerCase().trim())
            }
            styles={SelectStyles}
          />
        </InputContainer>
        <div className="flex-direction-row">
          <InputContainer>
            <InputLabel>Amount</InputLabel>
            <Input
              type="number"
              onFocus={(event) => event.target.select()}
              name="amount"
              value={record.amount}
              onChange={handleBillChange}
            />
          </InputContainer>

          <InputContainer>
            <InputLabel>Currency</InputLabel>
            <Select
              defaultValue={record.currency || "AED"}
              value={record.currency}
              onChange={(value: string) => {
                // setSelectedCurrency(value);
                setRecord({ ...record, currency: value });
              }}
              styles={{
                input: {
                  marginTop: 4,
                  fontWeight: "bold",
                  fontSize: 18,
                },
              }}
              data={currencies}
              searchable
              maxDropdownHeight={400}
              nothingFound="Nothing here"
              filter={(value, item: any) =>
                item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                item.value.toLowerCase().includes(value.toLowerCase().trim())
              }
            />
          </InputContainer>
        </div>
        {/* <Currency className="button is-static is-small is-bold">
            {localStorage.getItem("baseCurrency") || "AED"}
          </Currency> */}

        <div className="flex-direction-row">
          <InputContainer>
            <InputLabel>Issue Date</InputLabel>
            <CustomDatePicker
              clearable={false}
              styles={{
                input: {
                  border: "none",
                  fontWeight: "bolder",
                  fontSize: "18px",
                },
              }}
              maxDate={new Date(record.dueDate)}
              value={new Date(record.issueDate)}
              onChange={(date: any) => {
                setRecord((prev: any) => ({
                  ...prev,
                  issueDate: moment(date).format("YYYY-MM-DD"),
                }));
              }}
            />
          </InputContainer>
          <InputContainer>
            <InputLabel>Due Date</InputLabel>
            <CustomDatePicker
              clearable={false}
              minDate={new Date(record.issueDate)}
              // minDate={
              //   user.isDemo
              //     ? new Date(
              //         moment(user.createdAt)
              //           .subtract(6, "M")
              //           .startOf("month")
              //           .format("YYYY-MM-DD hh:mm")
              //       )
              //     : new Date(user.createdAt)
              // }
              styles={{
                input: {
                  border: "none",
                  fontWeight: "bolder",
                  fontSize: "18px",
                },
              }}
              value={new Date(record.dueDate)}
              onChange={(date: any) => {
                setRecord((prev: any) => ({
                  ...prev,
                  dueDate: moment(date).format("YYYY-MM-DD"),
                }));
              }}
            />
          </InputContainer>
        </div>

        <InputContainer>
          <InputLabel>{recordTypeName} No (Optional)</InputLabel>
          <Input
            name="referenceNumber"
            value={record.referenceNumber}
            onChange={handleBillChange}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Note to Self</InputLabel>
          <Input
            name="description"
            value={record.description}
            onChange={handleBillChange}
          />
        </InputContainer>

        {/* <InputContainer>
          <InputLabel>Cashflow category</InputLabel>
          <Select
            data={cashflowBucketOptions}
            placeholder={"Select a cashflow category"}
            // defaultValue={recordType}
            styles={SelectStyles}
            value={selectedCashflowBucket}
            onChange={(option: any) => {
              setSelectedCashflowBucket(option);
            }}
          />
        </InputContainer> */}
        <If condition={contact !== undefined}>
          <MapCategory
            mappings={contact?.cashflowCategoryMappings.filter(
              (mapping: any) => mapping.scenario.id === selectedScenario?.id
            )}
            mode="view"
          />
        </If>

        <Footer>
          <div className="buttons-invoice-container">
            {selectedBill && selectedBill?.id ? (
              <button
                onClick={() => {
                  handleUpdateStep &&
                    handleUpdateStep(InvoiceStep.EDIT_PAYMENT);
                }}
                style={{
                  backgroundColor: "#faded4",
                  color: "black",
                  width: "100%",
                }}
                className="button mt-4 is-bold button-invoice-edit"
              >
                Edit Payments
              </button>
            ) : (
              <button
                onClick={() => {
                  handleUpdateStep &&
                    handleUpdateStep(
                      InvoiceStep.ADD_PAYMENT,
                      record,
                      selectedCashflowBucket
                    );
                }}
                style={{
                  backgroundColor: "#faded4",
                  color: "black",
                  width: "100%",
                }}
                className="button mt-4 is-bold button-invoice-edit"
              >
                Add Payments
              </button>
            )}
            <button
              onClick={() => {
                createBill("create");
              }}
              style={{
                backgroundColor: COLORS.greenPrimary,
                color: "white",
                width: "100%",
              }}
              className="button mt-4 is-bold button-invoice"
            >
              Save
            </button>
          </div>

          {selectedBill && selectedBill.id ? (
            <></>
          ) : (
            <button
              onClick={() => {
                createBill("create_and_another");
              }}
              className="button is-text is-small mt-2"
            >
              Save and add another {recordTypeName.toLowerCase()}
            </button>
          )}
        </Footer>
      </Card>
    </Container>
  );
}

export default AddRecord;

const CardTitle = styled.h4`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
    margin-left: 6px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding: 25px;
`;

const InputContainer = styled.div`
  position: relative;
  margin: 8px;
  margin-top: 18px;
  flex: 1;
`;
const InputLabel = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;
const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  outline-width: 0;
  border-bottom: 1px solid #707070;
  font-weight: 600;
  font-size: 18px;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

const Currency = styled.div`
  position: absolute;
  right: 0;
  bottom: 6px;
`;

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #707070;
`;

const Footer = styled.div`
  font-size: 14px;
  color: #707070;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
